import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "remove-flagging-for-malicous-downloads-by-google"
    }}>{`Remove flagging for malicous downloads by Google`}</h1>
    <p>{`Help docs:`}</p>
    <p><a parentName="p" {...{
        "href": "https://developers.google.com/web/fundamentals/security/hacked/request_review"
      }}>{`https://developers.google.com/web/fundamentals/security/hacked/request_review`}</a>{`
`}<a parentName="p" {...{
        "href": "https://support.google.com/webmasters/answer/3258249"
      }}>{`https://support.google.com/webmasters/answer/3258249`}</a></p>
    <p>{`Go to Google Search Console  `}<a parentName="p" {...{
        "href": "https://search.google.com"
      }}>{`https://search.google.com`}</a>{` and search for the domain under "Properties" or add it and verify ownership by creating a TXT record on the domain with the given code.`}</p>
    <p>{`If the site is flagged there must be a SECURITY ISSUES alert on the Search Console page for this property.
You must check the URLs presented in the issues report to see if tehy still exist and/or still have malicious links.`}</p>
    <p>{`After this has been verified, click "Request Review" button, and provide a desciption of what was done to resolve the issue like:`}</p>
    <p>{`"all passwords changed, plugins and core reinstalled and moved to new server"`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      